




import Vue from 'vue';
import { vuetifyComponents } from '@/plugins/vuetify';

export default Vue.extend({
  name: 'user-card',
  components: {
    ...vuetifyComponents,
  },
  props: {
    user: Object,
  },
});
